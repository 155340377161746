import React from "react";

const Verstaerkung = () => {
    return (
        <div className="aboutone">
            <div className="columns is-multiline">
                <div className="column is-half">
                    <div className="container4-l bg-color has-text-centered center">
                        <h2>
                            Du möchtest Teil unseres <br />
                            professionellen und ambitionierten Teams werden?
                        </h2>
                        <br />
                        <p>
                            Wir sind immer auf der Suche nach Verstärkung für
                            unser großartiges Team. Wir freuen uns auf deine
                            Bewerbung!
                        </p>
                        <div className="has-text-centered">
                            <a
                                class="button preisrechner-btn preisrechner-btn-primary"
                                href="/karriere/"
                                id="btn_career_jetztbewerben"
                            >
                                Jetzt bewerben
                            </a>
                        </div>
                    </div>
                </div>
                <div className="column is-half">
                    <div className="container4-r has-text-centered"></div>
                </div>
            </div>
        </div>
    );
};

export default Verstaerkung;
