import React from "react";

const details = [
    { cstyle1: "container-l has-text-centered" },
    {
        cstyle1: "container-r bg-color has-text-centered center",
        title: "Wir sind Ihr zuverlässiger Partner für professionelle Sicherheitstechnik. Wir sichern Sie ab.",
        description:
            "Bei uns erhalten Sie den kompletten Service, von der kostenlosen Sicherheitsberatung bis hin zur Installation und Wartung Ihres Sicherheitssystems durch zertifizierte Sicherheitstechniker aus einer Hand. Das Team von MeinAlarm24 steht Ihnen bei allen Sicherheitsfragen mit Rat und Tat zur Seite.",
    },
    {
        cstyle1: "container2-l has-text-centered center",
        title: "Höchste Qualität. Renommierte Hersteller. Ausgezeichnete Anlagen. Maßgeschneiderte Lösungen.",
        description:
            "Bei unserer täglichen Arbeit legen wir besonderen Wert auf die Qualität unserer Lösungen, die wir nach Maß an Ihre Anforderungen anpassen. Unser Produktportfolio umfasst Alarmanlagen, Videoüberwachungsanlagen, elektronische Schließanlagen, Brandmeldeanlagen und Smart Home Lösungen, die wir nur von den renommiertesten europäischen und globalen Herstellern beziehen und die als Testsieger ausgezeichnet wurden.",
    },
    { cstyle1: "container2-r has-text-centered" },
    { cstyle1: "container3-l has-text-centered" },
    {
        cstyle1: "container3-r bg-color has-text-centered center",
        title: "Bei Ihnen Vorort. Und deutschlandweit.",
        description:
            "Mit mittlerweile über 10 Standorten deutschlandweit sind wir Ihr zuverlässiger Ansprechpartner Vorort. So verbinden wir die Partnerschaftlichkeit eines lokalen Anbieters mit der Professionalität eines bundesweittätigen Unternehmens und können Deutschland ein Stückchen sicherer machen.",
    },
];

const detailsM = [
    { cstyle1: "container-l has-text-centered" },
    {
        cstyle1: "container-r bg-color has-text-centered center",
        title: "Wir sind Ihr zuverlässiger Partner für professionelle Sicherheitstechnik. Wir sichern Sie ab.",
        description:
            "Bei uns erhalten Sie den kompletten Service, von der kostenlosen Sicherheitsberatung bis hin zur Installation und Wartung Ihres Sicherheitssystems durch zertifizierte Sicherheitstechniker aus einer Hand. Das Team von MeinAlarm24 steht Ihnen bei allen Sicherheitsfragen mit Rat und Tat zur Seite.",
    },
    { cstyle1: "container2-r has-text-centered" },
    {
        cstyle1: "container2-l has-text-centered center",
        title: "Höchste Qualität. Renommierte Hersteller. Ausgezeichnete Anlagen. Maßgeschneiderte Lösungen.",
        description:
            "Bei unserer täglichen Arbeit legen wir besonderen Wert auf die Qualität unserer Lösungen, die wir nach Maß an Ihre Anforderungen anpassen. Unser Produktportfolio umfasst Alarmanlagen, Videoüberwachungsanlagen, elektronische Schließanlagen, Brandmeldeanlagen und Smart Home Lösungen, die wir nur von den renommiertesten europäischen und globalen Herstellern beziehen und die als Testsieger ausgezeichnet wurden.",
    },
    { cstyle1: "container3-l has-text-centered" },
    {
        cstyle1: "container3-r bg-color has-text-centered center",
        title: "Bei Ihnen Vorort. Und deutschlandweit.",
        description:
            "Mit mittlerweile über 10 Standorten deutschlandweit sind wir Ihr zuverlässiger Ansprechpartner Vorort. So verbinden wir die Partnerschaftlichkeit eines lokalen Anbieters mit der Professionalität eines bundesweittätigen Unternehmens und können Deutschland ein Stückchen sicherer machen.",
    },
];

function Containers(props) {
    return (
        <div className="column is-half">
            <div className={props.details.cstyle1}>
                <h2>{props.details.title}</h2>
                <br />
                <p>{props.details.description}</p>
            </div>
        </div>
    );
}

function ContainersM(props) {
    return (
        <div className="column is-half">
            <div className={props.detailsM.cstyle1}>
                <h2>{props.detailsM.title}</h2>
                <br />
                <p>{props.detailsM.description}</p>
            </div>
        </div>
    );
}

const About = () => {
    return (
        <div className="aboutone">
            <div className="content-desktop only-desktop">
                <div className="columns is-multiline">
                    {details.map((detail) => (
                        <Containers details={detail} />
                    ))}
                </div>
            </div>
            <div className="content-mobile only-mobile">
                {detailsM.map((detail) => (
                    <ContainersM detailsM={detail} />
                ))}
            </div>
        </div>
    );
};

export default About;
