import React from "react";
import { graphql } from "gatsby";
import Header from "../components/Header";
import AboutMission from "../components/AboutMission";
import About from "../components/About";
import Howto2steps from "../components/Howto2steps";
import Verstaerkung from "../components/Verstaerkung";
import SevenDaysBanner from "../components/SevenDaysBanner";
import Footer from "../components/Footer";


const aboutus = () => {
  return (
    <div>
        <div>
            <div className="section static-site">
            <div className="section our-team">
                <div className=" static-hero">
                    <div className="body">
                    <div className="content-desktop only-desktop has-text-centered">
                        <div className="top-title-center">
                            <h1 className="top">Wir sind MeinAlarm24</h1>
                        </div>
                    </div>
                    <div className="content-mobile only-mobile has-text-centered">
                        <div className="top-title-center">
                            <h1 className="top">Wir sind MeinAlarm24</h1>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </div>
            <div className="section">
            <AboutMission/>
            <About/>
            <div className="section how-to2">
                <div className="body">
                    <div className="head-font">
                    <h2>
                    Haben Sie Interesse an professioneller Sicherheitstechnik 
                    von einem modernen und zuverlässigen Handwerksbetrieb?
                    </h2>
                    <Howto2steps/>
                            </div>
                        </div>
                    </div>
            <Verstaerkung/></div>
            <SevenDaysBanner cssclassName='with-background'/>
            <Footer />
        </div>
    </div>
  );               
};

export default aboutus;


