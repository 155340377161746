import React from "react";
import PriceCalculationButton from "./PriceCalculator/PriceCalculationButton";
import StringBuilder from "../helper/StringBuilder";
import Fade from "react-reveal/Fade";
import { Link } from "gatsby";

export default class Howto2steps extends React.Component {
    render() {
        let currentURL = "";
        if (typeof window !== `undefined`) {
            currentURL = window.location.pathname;
        }
        // console.log("slug is ", window.location.pathname == "/ueber-uns/");
        const slug =
            typeof this.props.city !== "undefined"
                ? this.props.city.slug
                : this.props.city;

        let stringBuilder = new StringBuilder(
            this.props.city,
            this.props.product
        );
        let icon3Route = "/img/image_3_wir_installieren_fuer_sie_Einbruch.svg";
        if (this.props.product) {
            icon3Route =
                "/img/image_3_wir_installieren_fuer_sie_" +
                this.props.product +
                ".svg";
        }
        return (
            <div className="section how-to2">
                <div className="body">
                    <div className="columns">
                        <div className="column has-text-centered">
                            <h2 className="title">
                                In 3 einfachen Schritten zu{" "}
                                {stringBuilder.getHowToTitle() +
                                    stringBuilder.getInStadtUndUmgebung()}
                            </h2>
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column has-text-centered">
                            <div className="card">
                                <div className="card-image">
                                    <Fade top>
                                        <figure className="image">
                                            <img
                                                src="/img/image_1_bedarf_ermitteln.svg"
                                                alt="Bedarf ermitteln"
                                            />
                                        </figure>
                                    </Fade>
                                </div>
                                <div className="card-content">
                                    <div className="content">
                                        <div className="title">
                                            1. Preis berechnen
                                        </div>
                                        <div className="text">
                                            Geben Sie die Informationen zu der
                                            Immobilie an, die Sie sichern
                                            möchten. Sie erhalten eine
                                            detaillierte Preiskalkulation für
                                            eine{" "}
                                            {stringBuilder.getSingularProductName() +
                                                stringBuilder.getInStadtUndUmgebung()}
                                            .
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="column has-text-centered">
                            <div className="card">
                                <div className="card-image">
                                    <Fade top>
                                        <figure className="image">
                                            <img
                                                src="/img/image_2_kostenlose_beratung.svg"
                                                alt="Kostenlose Beratung"
                                            />
                                        </figure>
                                    </Fade>
                                </div>
                                <div className="card-content">
                                    <div className="content">
                                        <div className="title">
                                            2. Sicherheitskonzept abstimmen
                                        </div>
                                        <div className="text">
                                            Ob telefonisch oder bei Ihnen vor
                                            Ort - unsere Sicherheitsberater
                                            {stringBuilder.getAusStadtUndUmgebung()}{" "}
                                            stehen Ihnen bei allen Fragen rund
                                            um Ihre Sicherheit mit Rat und Tat
                                            zur Verfügung.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="column has-text-centered">
                            <div className="card">
                                <div className="card-image">
                                    <Fade top>
                                        <figure className="image">
                                            <img
                                                src={icon3Route}
                                                alt="Wir installieren für sie"
                                            />
                                        </figure>
                                    </Fade>
                                </div>
                                <div className="card-content">
                                    <div className="content">
                                        <div className="title">
                                            3. Wir installieren die Anlage
                                        </div>
                                        <div className="text">
                                            Lehnen Sie sich zurück und fühlen
                                            Sie sich sicher: Unser Team
                                            übernimmt auch die Installation
                                            Ihrer neuen{" "}
                                            {stringBuilder.getSingularProductName()}{" "}
                                            mit eigenen Sicherheitstechnikern.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column has-text-centered">
                            <PriceCalculationButton
                                id="btn_3steps_jetztstarten"
                                text="Jetzt starten"
                                city={slug}
                                product={this.props.product}
                            />
                        </div>
                    </div>
                    {currentURL == "/alarmanlagen-haus/" ||
                    currentURL == "/alarmanlagen-haus" ? (
                        <div
                            // className="section how-to2"
                            style={{
                                // fontSize: "1.5rem",
                                fontFamily:
                                    '"Font Awesome 5 Free", "Source Sans Pro"',
                                color: "#193351",
                                wordSpacing: "2px",
                                textAlign: "justify",
                                paddingLeft: "3rem",
                                paddingRight: "3rem",
                            }}
                        >
                            <h2 className="head-font custom-header">
                                Vorteile von Alarmanlagen - wie sie beim
                                Einbruchschutz helfen
                            </h2>
                            <p>
                                In den letzten Jahren hat die Zahl der
                                Wohnungseinbrüche und Hauseinbrüche in
                                Deutschland zugenommen. Dabei handelt es sich
                                nicht mehr nur um Gelegenheitstäter oder
                                Einzeltäter. Man muss sich aber nicht mit dem
                                Schicksal der Diebe abfinden, auch wenn es sich
                                um Profis handelt. Die Professionalisierung der
                                Sicherheit Ihres Eigentums ist die bessere
                                Option. Wenn Sie sorgenfrei verreisen wollen,
                                sollten Sie die Installation einer Alarmanlage
                                im Haus in Betracht ziehen.
                            </p>
                            <ul class="spaces newSectionText">
                                {/* <br /> */}
                                <li>
                                    <h3> Alarmanlagen wirken abschreckend</h3>
                                    Alarmanlagen haben eine nicht zu
                                    vernachlässigende abschreckende Wirkung.
                                    Alarmanlagen, die von außen sichtbar sind,
                                    können Kriminelle von Einbruchsversuchen
                                    abhalten. Vorbeugender Schutz verhindert
                                    Schäden an den Eingängen, indem er Einbrüche
                                    wirksam unterbindet.
                                </li>
                                {/* <br /> */}
                                <li>
                                    <h3>
                                        {" "}
                                        Alarmanlagen schrecken Einbrecher ab
                                    </h3>
                                    Ein Einbruchsversuch ist in der Regel der
                                    Auslöser für einen Alarm. Die Diebe sind in
                                    erster Linie daran interessiert, möglichst
                                    leise zu sein, um nicht aufzufallen. Wenn
                                    ein Alarm ausgelöst wird, ergreifen die
                                    Einbrecher in der Regel schnell die Flucht.
                                    Versuchen Sie, wenn Sie vor Ort sind, sich
                                    so viele Informationen wie möglich über den
                                    flüchtenden Einbrecher zu merken und diese
                                    später der Polizei zu melden.
                                </li>
                                {/* <br /> */}
                                <li>
                                    <h3>
                                        Alarmanlagen erregen die Aufmerksamkeit
                                        der Nachbarn
                                    </h3>
                                    Die Alarmanlage weckt in der Regel die
                                    Nachbarn auf, wenn sie ertönt. Oft können
                                    sogar Passanten die Alarmanlage erkennen und
                                    die notwendigen Schritte (z. B. den Anruf
                                    bei der Polizei) einleiten. Wenn Sie sich zu
                                    diesem Zeitpunkt im Haus befinden, werden
                                    Sie von der Alarmanlage sofort
                                    benachrichtigt.
                                </li>
                                {/* <br /> */}
                                <li>
                                    <h3>
                                        {" "}
                                        Leicht zu installierende Alarmanlagen
                                    </h3>
                                    Wenn die Installation professionell
                                    durchgeführt wird, kann die Inbetriebnahme
                                    einer Alarmanlage schnell und einfach
                                    erfolgen. Größere Eingriffe in die Fassade
                                    oder die Bausubstanz sind selten nötig.
                                    Moderne Alarmanlagen können auch drahtlos
                                    per Funk betrieben werden, so dass das
                                    Verlegen teurer Kabel entfällt. Bei Bedarf
                                    sind aber auch drahtgebundene Lösungen
                                    möglich. Die Installation Ihrer Alarmanlage
                                    sollte von einem kompetenten und erfahrenen
                                    Unternehmen durchgeführt werden. So sind Sie
                                    sicherer und erhalten auf Anhieb eine
                                    qualitativ hochwertige Installation.
                                    Sprechen Sie uns dazu gerne an!
                                </li>
                                {/* <br /> */}
                                <li>
                                    <h3>
                                        Moderne Alarmanlagen sind
                                        multifunktional
                                    </h3>
                                    Moderne Alarmanlagen können dank moderner
                                    Kommunikationstechnik multifunktional sein.
                                    Die Alarmanlage löst sofort einen Notruf aus
                                    und benachrichtigt die Polizei und den
                                    Sicherheitsdienst. In Sekundenschnelle ist
                                    dann Hilfe vor Ort. Zuverlässige
                                    Alarmanlagen können auch Feuer, Rauch und
                                    Wasser melden. Mit einer modernen
                                    Alarmanlage erhalten Sie eine umfassende
                                    Sicherheitslösung, die Ihre Familie und Ihr
                                    Eigentum schützt.
                                </li>
                            </ul>
                        </div>
                    ) : (
                        ""
                    )}
                    {currentURL == "/alarmanlagen-firma/" ||
                    currentURL == "/alarmanlagen-firma" ? (
                        <div
                            // className="section how-to2"
                            style={{
                                // fontSize: "1.5rem",
                                fontFamily:
                                    '"Font Awesome 5 Free", "Source Sans Pro"',
                                color: "#193351",
                                wordSpacing: "2px",
                                textAlign: "justify",
                                paddingLeft: "3rem",
                                paddingRight: "3rem",
                            }}
                        >
                            <h2 className="head-font custom-header">
                                Alarmanlagen für Firmen
                            </h2>
                            <p>
                                Kriminelle bevorzugen schnelle und einfache
                                Wege, um an ihr Ziel zu gelangen. Professionelle
                                Alarmanlagen machen dies unmöglich. Sie werden
                                oft schon vor dem Einbruchsversuch des Täters
                                erkannt und mit akustischen Signalanlagen (z.B.
                                mit einer Alarmsirene oder einem Blinklicht
                                darauf) für alle deutlich signalisiert. Oft
                                schreckt schon das Prinzip der Abschreckung die
                                Täter von Ihrem Vorhaben ab. Kommt es trotz
                                allem zu einem Versuch, kommt die zweite
                                Barrikade, der mechanische/elektronische
                                Einbruchschutz, ins Spiel.
                            </p>
                            <ul class="spaces newSectionText">
                                <li>
                                    <h3>
                                        Für welche Funktionen und Aufgaben sind
                                        Alarmanlagen gut?
                                    </h3>
                                    Bei einer Einbruchmeldeanlage wird zwischen
                                    zwei Aufgaben unterschieden:
                                    Anwesenheitsüberwachung: Bei dieser Variante
                                    werden Fenster und Türen durch Alarmmelder
                                    überwacht. Im Falle der Abwesenheit: Dazu
                                    gehört nicht nur die Überwachung der
                                    Außenwand, sondern auch die Überwachung des
                                    Innenraums. Die Installation von
                                    Alarmanlagen in der Firma, die Türschließer,
                                    Fenstersicherungen und Bewegungsmelder zu
                                    aktivieren, kann helfen, Einbrüche zu
                                    verhindern. Sie können die Installation
                                    zusammen mit einer Anbindung an einen
                                    örtlichen Wachdienst vornehmen lassen. Wir
                                    bieten Ihnen eine professionelle Beratung
                                    und ein kostenloses Angebot, das auf Ihr
                                    spezielles Objekt zugeschnitten ist. Als
                                    Fachbetrieb bieten wir persönliche Beratung,
                                    Planung, Montage, Wartung und einen
                                    24-Stunden-Notdienst.
                                </li>
                                <li>
                                    <h3>
                                        Drahtlose Alarmanlagen bieten mehr
                                        Schutz vor Einbrechern
                                    </h3>
                                    Unsere Empfehlung: Schützen Sie Ihre
                                    privaten und gewerblichen Nutzungen mit dem
                                    besten Schutz. Funkalarmanlagen bieten
                                    elektronische Sicherheit für das
                                    abgesicherte Objekt. Wir empfehlen,
                                    zusätzlich eine mechanische Absicherung in
                                    Anspruch zu nehmen. Über die
                                    <Link
                                        to="/schliessanlagen"
                                        style={{
                                            marginLeft: "2px",
                                            marginRight: "2px",
                                        }}
                                    >
                                        elektronische Zutrittskontrolle
                                    </Link>
                                    können sich nur autorisierte Personen Zugang
                                    zum Gebäude verschaffen. Mit erweiterbarem
                                    Zubehör wie Bewegungsmeldern und
                                    Glasbruchmeldern lässt sich die
                                    Produktpalette ausbauen. Sie verfügen auch
                                    über eine Videoüberwachung außerhalb und
                                    innerhalb ihrer Objekte.
                                </li>
                            </ul>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        );
    }
}
